export default {
    foot: {
        nav1: "Page",
        nav2: "comporte",
        nav3: "Transactions",
        nav4: "Monnaie",
        nav5: "Actifs",
    },
    userLevel: {
        level0: "Utilisateurs ordinaires",
        level1: "Utilisateurs Silver",
        level2: "Utilisateurs Gold",
        level3: "Utilisateurs Platinum",
        level4: "Utilisateurs de diamants",
        level5: "Utilisateur King",
        level6: "Utilisateur suprême",
    },
    currenctType: {
        title: "Veuillez sélectionner une devise",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dollars américains',
        currenctType15: 'Dollar de Hong Kong',
        currenctType16: 'Dollar de Taïwan',
        currenctType17: 'Monnaie cheval',
        currenctType18: 'La nouvelle monnaie',
        currenctType19: 'Yen japonais',
        currenctType20: 'Euros',
        currenctType21: 'Dollar australien',
        currenctType22: 'Rupiah indonésienne',
        currenctType23: 'Livre sterling',
        currenctType24: 'Baht thaïlandais',
        currenctType25: 'Dollars canadiens',
        currenctType26: 'Dong vietnamien',
        currenctType27: 'Monnaie coréenne',
        currenctType28: 'Monnaie de Macao',
    },
    account: {
        login: "Connexion",
        label_eMail: "Boîte aux lettres",
        place_eMail: "Veuillez saisir votre adresse électronique",
        tip_eMail: "Veuillez saisir correctement votre adresse électronique",
        label_password: "Mot de passe",
        place_password: "Veuillez saisir votre mot de passe",
        tip_password: "Veuillez saisir le mot de passe correct",
        no_account: "Vous n'avez pas encore de compte ?",
        register: "S'inscrire",
        send: "Envoyer le code de vérification",
        label_code: "Code de vérification par courriel",
        place_code: "Code de vérification par courriel",
        tip_code: "Veuillez saisir votre code de vérification de l'adresse électronique",
    },
    assets: {
        title: "Actifs",
        total_assets: "Total Assets",
        recharge: "recharge",
        withdraw: "Retirer",
        available: "disponible",
        freeze: "gelé",
        total: "Total",
        label_bank: "Monnaie",
        place_bank: "Veuillez sélectionner une devise",
        tip_bank: "Veuillez sélectionner une devise",
        label_amount: "Montant",
        place_amount: "Veuillez saisir le montant",
        tip_amount: "Veuillez saisir le montant correct",
        label_password: "Mot de passe",
        place_password: "Veuillez saisir votre mot de passe",
        tip_password: "Veuillez saisir le mot de passe correct",
        label_balance: "USDT disponibles",
        all: "Tous les retraits",
        no_password: "Veuillez définir cette option pour la sécurité de vos fonds",
        withdraw_psd: "Mot de passe de retrait",
        label_wallet: "Portefeuille",
        place_wallet: "Veuillez sélectionner Wallet",
        tip_wallet: "Veuillez sélectionner Wallet",
        link_type: "TYPE DE CHAINE",
        upload_credentials: "Télécharger les informations d'identification",
        transfer: "Transfert",
        fundTransfer:"Transfert de fonds",
        flashExchange: "Échange flash",
        from: "Formulaire",
        to: "Pour",
        select_breed: "Sélectionner la devise",
        transferQuantity: "Quantité à transférer",
        tab1: "Crypto",
        tab2: "Contrat",
        tab3: "Option",
        address: "Adresse",
        place_inner: "Veuillez entrer",
    },
    currency: {
        position: "Position",
        drawer_title: "currency_trade",
        now: "courant",
        open: "Ouvert",
        volume: "Volume",
        height: "Plus haut",
        low: "Plus bas",
        buy: "Achat long",
        sell: "Vente à découvert",
        name: "nom",
        direction: "direction",
        crm_order: "Confirmer l'ordre",
        now_price: "prix_actuel",
        type_time: "Delivery time (left slide for higher returns)",
        second: "secondes",
        buy_amount: "Montant de l'achat",
        available: "disponible",
        service_fee: "commission",
        min_number: "Minimum ",
        errorTip1: "Montant entré par erreur",
        errorTip2: "Le solde disponible est insuffisant",
        auto_jump: "Ordre de saut automatique après",
        keep: "Continuer à passer une commande",
        revenue: "Bénéfices attendus",
    },
    home: {
        quick: "Quick coin flush",
        support: "Support",
        nav1: "Partage",
        nav2: "Portefeuille",
        nav3: "Info",
        nav4: " Tutoriels ",
        nav5: " Service clientèle ",
        nav6: " Exploitation minière ",
        nav7: " À propos de nous ",
        nav8: "Abonnements aux nouvelles pièces",
        increase: "Up List",
        volume: "Volume",
        table_name: "Nom",
        table_lastPrice: "Dernier prix",
        table_increase: "Augmentation/Diminution",
        table_volume: "Volume",
        customer_service: "Service à la clientèle",
        language_switching: "Conversion de langue",
        futures_trading: "Trading à terme",
        pledge_mining: "Mines en gage",
    },
    lockming: {
        profit: "Recent (daily earnings)",
        cycle: "Cycle financier",
        limit: "Limite unique",
        dividend_time: "Heure de versement des dividendes",
        every_day: "quotidien",
        funds: "Fonds bloqués",
        back: "Rendement à l'échéance",
        ransom: "Remboursement anticipé",
        label_amount: "Montant du gage",
        place_amount: "Please enter,,,",
        available: "disponible",
        expect_income: "Revenu attendu",
        title: "L'exploitation minière verrouillée",
        tip: "Exploitation minière pour le revenu",
        label_today_income: "Revenus estimés aujourd'hui",
        label_total_income: "Revenu cumulé",
        label_order: "Commandes en dépôt",
        label_number: "Montant unique",
        daily_return: "Rendement quotidien",
        subscribe: "subscribe",
        position: "Position",
        label_state: "Statut",
        subscribe_time: "Heure de souscription",
        expiration_time: "Heure d'expiration",
        income: "revenu",
        errorTip1: "Veuillez entrer le montant",
        tip_tit: "Êtes - vous sûr de vouloir quitter tôt",
    },
    market: {
        spots: "Disponible en stock",
        contract: "Contrats",
        currency_trans: "Opérations de change",
        increase: "Principaux gagnants",
        volume: "Chiffre d'affaires",
        label_name: "Nom",
        label_lastPrice: "Dernier prix",
        table_increase: "Vers le haut ou vers le bas",
        table_volume: "Chiffre d'affaires",
    },
    my: {
        details: "Détails",
        help: "Centre d'aide",
        information: "Information",
        language: "Language switch",
        password: {
            account_title: "Changer le mot de passe",
            withdraw_title: "Changer de mot de passe",
            label_oldPsd: "Ancien mot de passe",
            place_oldPsd: "Veuillez saisir votre ancien mot de passe",
            label_newPsd: "Nouveau mot de passe",
            place_newPsd: "Veuillez saisir un nouveau mot de passe",
            label_crmPsd: "Confirmer le mot de passe",
            place_crmPsd: "Veuillez saisir à nouveau un nouveau mot de passe",
            withdraw_tip: "Si vous n'avez pas défini de mot de passe de retrait, veuillez le laisser vide",
            password_tips: "Le mot de passe doit être une combinaison de 6 ~ 16 chiffres + chiffres",
        },
        address: {
            title: "Adresse du portefeuille",
            account_number: "Numéro de compte",
            add_btn: "Ajouter une adresse",
            label_type: "Sélectionner le type",
            place_type: "Veuillez sélectionner le type",
            label_number: "Numéro de compte",
            place_number: "Veuillez saisir le numéro de compte",
            wallet_address: 'Adresse du portefeuille',
            bank_name: 'Nom de la Banque',
            bank_address: "Adresse de la Banque",
            name: "Nom et prénom",
            payee_address: 'Adresse du bénéficiaire',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nom de la branche",
            branch_code: "Code de succursale",
            bank_code: "Code de la Banque",
            phone: "Numéro de téléphone portable",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Veuillez entrer le contenu",
        },
        history: {
            title: "History",
            recharge_record: "recharge_record",
            withdraw_record: "Enregistrement de retrait",
            withdraw: "Retraits",
            number: "nombre",
            currency: "devise",
            state: "Statut",
            state0: "En attente",
            state1: "Completed",
            state2: "Rejected",
        },
        identity: {
            title: "Authentification",
            label_country: "Sélectionnez une nationalité",
            place_country: "Veuillez sélectionner une nationalité",
            label_name: "Nom",
            place_name: "Veuillez saisir votre nom",
            label_id: "Numéro d'identification",
            place_id: "Veuillez saisir votre numéro de carte d'identité/de permis de conduire/de passeport",
        },
        my: {
            list1: "Authentification",
            list2: "Gestion des commandes",
            list3: "Historique des fonds",
            list4: "Adresse du portefeuille",
            list5: "Changer de langue",
            liste6: "Inviter des amis",
            liste7: "Service clientèle",
            liste8: "Tutoriels",
            list9: "Changer de mot de passe",
            logout: "Déconnexion",
        },
        order: {
            title: "Position",
            direction: "direction",
            sell_price: "taux de vente",
            number: "quantité",
            type: "Délai de livraison",
            buy_price: "Prix de l'offre",
            profit: "profit/perte",
            sell_time: "heure de vente",
        },
        share: {
            title: "Inviter des amis",
            save_ewm: "Enregistrer le code QR",
            invite_link: "Lien d'invitation",
            copy_address: "Copier l'adresse",
            invite_code: "Code d'enregistrement",
            copy_code: "Copier le contenu",
            tip: "S'inscrire via le code QR ou le lien ci-dessus",
        },
    },
    transaction: {
        spots: "spot",
        contract: "contrat",
        currency_trans: "currency_transactions",
        exchange: "flash",
        sustainable: "perpétuel",
        warehouse: "position-by-position",
        jump_lever: "levier de saut",
        sell: "vendre",
        buy: "acheter",
        available: "disponible",
        check_price: "prix_limite",
        market_price: "prix_du_marché",
        number: "quantité",
        max: "max",
        bail: "marge",
        buy_long: "Achat d'une position longue",
        sell_short: "Vente à découvert",
        last_price: "Dernier prix",
        now_entrust: "current_entrust",
        now_position: "Position actuelle",
        table_amount: "Montant du retrait",
        is_reduce: "Réduire la position uniquement",
        shi: "Oui",
        fou: "Non",
        position_number: "Nombre de positions détenues",
        is_profit: "Profit réalisé",
        wei: "pas encore",
        open_price: "Prix ouvert",
        mark_price: "Prix marqué",
        flat_price: "prix_de_niveau_fort",
        trim_lever: "Ajuster l'effet de levier",
        flat_store: "Close Position",
        trim_bail: "Ajuster la marge",
        reduce_bail: "Réduire la marge",
        now_bail: "Marge de la position actuelle",
        max_add: "Augmentation maximale",
        refer_flat: "Prix forfaitaire fort de référence ajusté",
        expect_profit: "Bénéfice/perte attendu(e)"
    },
    common: {
        confirm: "Confirm",
        all: "Tous",
        close: "Annuler",
        copy_success: "Copie réussie !",
        copy_error: "La copie a échoué !",
        place_input: "Veuillez saisir le contenu",
        more: "Plus",
        main: "Figu",
        sub: "Sous",
        success: "Avec succès!",
        cancel_tip : "Êtes-vous sûr de vouloir annuler ?" ,
        notice : "Notification",
        logout_tip: "Confirmer le retrait?",
        tip: "Conseils",
        day: "Jours"
    },
    params: {
        pass1: "If no withdrawal password has been set, please enter the login password",
        idType: "Document type",
        idMessage: "ID photo",
        idType3: "Passport",
        idType2: "Driver's license",
        idType1: "ID card",
        totalProfit: "Total Profit",
        todayProfit: "Today Profit",
        transType0: "En stock",
        transType1: "Le contrat",
        directionType0: "Acheter",
        directionType1: "Vendre",
        lockStateType0: "En fonctionnement",
        lockStateType1: "C'est terminé",
        lockStateType2: "Annulé",
    },
    city: {
		albania: "Albanie",
		algeria: "Algérie",
		argentina: "Argentine",
		armenia: "Arménie",
		australia: "Australie",
		pakistan: "Pakistan",
		austria: "Autriche",
		bahrain: "Bahreïn",
		belgium: "Belgique",
		bosnia_and_Herzegovina: "Bosnie _ Herzégovine",
		brazil: "Brésil",
		brunei: "Brunei",
		bulgaria: "Bulgarie",
		cambodia: "Cambodge",
		canada: "Au Canada",
		cameroon: "Cameroun",
		chile: "Chili",
		colombia: "Colombie",
		costa_Rica: "Costa Rica",
		croatia: "Croatie",
		cyprus: "Chypre",
		czech_Republic: "République tchèque",
		denmark: "Danemark",
		dominican_Republic: "République dominicaine",
		egypt: "Égypte",
		estonia: "Estonie",
		ethiopia: "Éthiopie",
		finland: "Finlande",
		france: "France",
		georgia: "Géorgie",
		germany: "Allemagne",
		ghana: "Ghana",
		greece: "Grèce",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Chine",
		hungary: "Hongrie",
		iceland: "Islande",
		ireland: "Irlande",
		italy: "Italie",
		india: "Inde",
		indonesia: "Indonésie",
		israel: "Israël",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japon",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Corée du Sud",
		kuwait: "Koweït",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonie",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		macao_China: "Macao, Chine",
		macedonia: "Macédoine",
		malaysia: "Malaisie",
		malta: "Malte",
		mexico: "Mexique",
		moldova: "Moldavie",
		monaco: "Monaco",
		mongolia: "Mongolie",
		montenegro: "République du Monténégro",
		morocco: "Maroc",
		myanmar: "Myanmar",
		netherlands: "Pays _ Ba",
		new_Zealand: "Nouvelle _ Zélande",
		nepal: "Népal",
		nigeria: "Nigéria",
		norway: "Norvège",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Pérou",
		philippines: "Philippines",
		poland: "Pologne",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roumanie",
		russia: "Russie",
		republic_of_Trinidad_and_Tobago: "République de Trinité _ et _ Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Arabie saoudite",
		serbia: "Serbie",
		singapore: "Singapour",
		slovakia: "Slovaquie",
		slovenia: "Slovénie",
		south_Africa: "Afrique du Sud",
		spain: "Espagne",
		sri_Lanka: "Sri Lanka",
		sweden: "Suède",
		switzerland: "Suisse",
		taiwan_China: "Taïwan, Chine",
		tajikistan: "Tadjikistan",
		tanzania: "Tanzanie",
		thailand: "Thaïlande",
		turkey: "Turquie",
		turkmenistan: "Turkménistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Émirats arabes unis",
		united_Kingdom: "Royaume _ Uni",
		united_States: "États _ Unis",
		uzbekistan: "Ouzbékistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaïdjan",
		bangladesh: "Bangladesh",
		belarus: "Biélorussie",
		belize: "Belize",
		benin: "Bénin",
		bhutan: "Bhoutan",
		bolivia: "Bolivie",
		botswana: "Botswana",
		british_Virgin_Islands: "Îles Vierges britanniques",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cap _ Vert",
		cayman_Islands: "Îles Caïmans",
		central_African_Republic: "République centrafricaine",
		chad: "Tchad",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Bu)",
		democratic_Republic_of_the_Congo: "Congo (or)",
		djibouti: "Djibouti",
		ecuador: "Équateur",
		el_Salvador: "Salvador",
		equatorial_Guinea: "Guinée équatoriale",
		eritrea: "Érythrée",
		fiji: "Fidji",
		gabon: "Gabon",
		gambia: "Gambie",
		greenland: "Groenland",
		guatemala: "Guatemala",
		guinea: "Guinée",
		haiti: "Haïti",
		isle_of_Man: "Île de Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaïque",
		jordan: "Jordanie",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Libye",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Maurice",
		mozambique: "Mozambique",
		namibia: "Namibie",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corée du Nord",
		reunion: "La réunion",
		san_Marino: "Saint _ Marin",
		senegal: "Sénégal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalie",
		sudan: "Soudan",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Syrie",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisie",
		united_States_Virgin_Islands: "Îles Vierges américaines",
		uganda: "Ouganda",
		uruguay: "Uruguay",
		vatican: "Le Vatican",
		yemen: "Yémen",
		yugoslavia: "Yougoslavie",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
		china: "Chine",
	}
};
