<template>
  <router-view></router-view>
</template>
<script>
export default {
  mounted() {
    if (this._isMobile()) {
      // window.location.href = "https://www.baidu.com/";
      console.log("手机端");
    } else {
      console.log("pc端");
      document.documentElement.style.fontSize = (375 / 750) * 16 + "px";
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="less">
body {
  background-color: #010118 !important;

  --color-theme-green: #0ab481;
  --color-theme-red: #f05b5b;
  --color-theme-grey: #999999;
  --color-theme-main: #222222;
  --color-theme-orange: #f56b2e;
  --bg-theme-green: #0ab481;
  --bg-theme-red: #f05b5b;
  --bg-theme-grey: rgba(153, 153, 153, 0.1);
  --bg-theme-grey: #061121;
  --bg-img-card: url(/static/image/card-bg.png);
  --bg-card-green: #f1fffa;

  --bg-app: #010118;
  --bg-app2: #061121;
  --theme-main: #1199fa;

  .el-select-dropdown {
    .el-select-dropdown__item {
      font-size: 24px !important;
      height: 60px;
      line-height: 60px;
    }

    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      color: var(--color-theme-green) !important;
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  .el-form-item {
    margin-bottom: 40px;
  }

  .el-button {
    background-color: var(--theme-main);
    height: 80px;
    color: #fff;
    width: 100%;
    border-radius: 15px;
    font-size: 28px;
  }
  .el-divider {
    background-color: rgba(153, 153, 153, 0.1);
  }
  .el-input__inner {
    font-size: 28px;
  }

  .cell {
    font-size: 24px;
  }

  .el-message-box {
    width: 80% !important;
    padding: 20px;

    .el-message-box__header {
      padding: 30px;
      .el-icon-close {
        font-size: 28px;
      }
    }

    .el-message-box__title {
      font-size: 36px;
    }

    .el-message-box__content {
      padding: 30px;
      font-size: 28px;
    }

    .el-message-box__btns {
      .el-button {
        width: auto;
        padding: 0 40px;
      }
    }

  }

  .el-table {
    color: #fff;
    background: var(--bg-theme-grey);

    tr {
      background: var(--bg-theme-grey);
    }

    th.el-table__cell {
      background: var(--bg-theme-grey);
    }

    td.el-table__cell,
    th.el-table__cell.is-leaf {
      // border-bottom: 1 solid #EBEEF5;
      border-color: var(--color-theme-grey);
      border-color: rgba(153, 153, 153, 0.1);
    }

    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: rgba(153, 153, 153, 0.1);
    }
    .rate {
      overflow: hidden;
    }
  }

  .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: rgba(153, 153, 153, 0.1) !important;
  }

  .el-button {
    border: none;
  }
}
</style>
